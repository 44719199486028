import * as React from "react";
import FeatureItem from "../components/FeatureItem";
import FeaturesGroup from "../components/FeaturesGroup";
import WebsiteLayout from "../layouts/WebsiteLayout";

import { faCalendar, faCode, faCog, faGlobe, faMobile } from '@fortawesome/free-solid-svg-icons';
import RegisterCta from "../components/RegisterCta";
import { Link } from "gatsby";

import { featureGroups } from "../data/features";

const Features = (props) => {

    const displayableFeatureGroups = featureGroups.filter(function(featureGroup) {

        return featureGroup.showOnFeaturesPage != false;

    });

    return (
        <main>
            <WebsiteLayout location={props.location} title="Features">
                <section className="content-header content-header-1 -blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="heading">Features</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">

                        {displayableFeatureGroups.map(function(featureGroup, index) {

                            const alignment = (index+1) % 2 === 0 ? 'left': 'right';

                            return (

                                <React.Fragment>

                                    <div className="row">

                                        <FeaturesGroup featureGroup={featureGroup} alignment={alignment}>

                                            {!featureGroup.hideTitle &&
                                            
                                                <React.Fragment>
                                                    <h3>{featureGroup.title}</h3>
                                                </React.Fragment>

                                            }

                                            {

                                                featureGroup.features.map(function(feature) {

                                                    if(feature.disabled) return null;

                                                    return <FeatureItem icon={feature.icon} title={feature.title} text={feature.description} featureStatus={feature.featureStatus} />;

                                                })

                                            }

                                        </FeaturesGroup>

                                        {index+1 !== displayableFeatureGroups.length &&
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <hr/>
                                            </div>
                                        }

                                    </div>

                                </React.Fragment>

                            );

                        })}

                        {/* {featuresList.map(function(group, index) {

                            const alignment = (index+1) % 2 === 0 ? 'left': 'right';

                            // console.log(index);

                            return (<React.Fragment>
                                
                                }
                            </React.Fragment>);     

                        })} */}
                    </div>
                </section>
                <RegisterCta />
            </WebsiteLayout>
        </main>
    );

}

export default Features;