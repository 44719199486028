import React from "react";

// import FontAwesomeIcon from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FEATURE_STATUS_BETA, FEATURE_STATUS_COMING_SOON } from "../../data/features";
import { Link } from "gatsby";

export default function FeatureItem({ icon, title, text, featureStatus }) {

    const faIcon = <FontAwesomeIcon icon={icon} size="3x" className={"ico-services"} />;
    return (
        <React.Fragment>
        <div className="row">
            <div className="col-xs-2 text-center">
                {faIcon}
            </div>
            <div className="col-xs-10">
                <h2 className="sub-heading">{title}</h2>
                <p>{text}</p>

                {featureStatus && featureStatus == FEATURE_STATUS_COMING_SOON &&
                    <div>
                    <h3>
                      <Link to={"/features/coming-soon"} >
                        <span class="label primary-label -blue">Coming Soon</span>
                      </Link>
                    </h3>
                  </div>
                }

                {featureStatus && featureStatus == FEATURE_STATUS_BETA &&
                    <div>
                    <h3>
                      <Link to={"/features/in-beta"} >
                        <span class="label primary-label -blue">In Beta</span>
                      </Link>
                    </h3>
                  </div>
                }

            </div>
        </div>
        </React.Fragment>
    );

};