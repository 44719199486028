import React from "react";

import image from '../../img/1.jpg';

export default function FeaturesGroup({ featureGroup, alignment, children }) {

    return (
        <React.Fragment>
        <div className={`desc pull-${alignment} col-xs-12 col-sm-12 col-md-6 col-lg-6`}>
            {children}
        </div>

        <div className="image col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {featureGroup.image &&
                <React.Fragment>

                    <img src={featureGroup.image.imageUrl} className="img-responsive" alt={featureGroup.image.imageAlt} />

                </React.Fragment>
            }
        </div>
        </React.Fragment>
    );

};