import { faBriefcase, faBuilding, faCalendar, faCalendarAlt, faCog, faFileContract, faSign, faTachometerAlt, faTasks, faUsers } from "@fortawesome/free-solid-svg-icons";
import { LITE_PLAN, PLUS_PLAN, PROFESSIONAL_PLAN } from "./plans";

import image1 from '../img/1.jpg';

const FEATURE_TYPE_QUOTA_BASED = 'feature_type_quota_based';

const QUOTA_TYPE_NUMBER_EMPLOYEES = 'quota_type_number_employees';
const QUOTA_TYPE_NUMBER_USERS = 'quota_type_number_users';
const QUOTA_TYPE_UNLIMITED_USERS = 'quota_type_unlimited_users';
const FEATURE_TYPE_FEATURE = 'feature_type_feature';
const QUOTA_TYPE_UNLIMITED = 'feature_type_unlimited';

const FEATURE_STATUS_STABLE = 'feature_status_stable';
const FEATURE_STATUS_BETA = 'feature_status_beta';
const FEATURE_STATUS_COMING_SOON = 'feature_status_coming_soon';

const featureGroups = [
    {
        title: 'System',
        description: null,
        hideTitle: true,
        showOnFeaturesPage: false,
        features: [
            {
                title: 'Advanced Users',
                description: 'Advanced Software Users allow backend access to your system to perform all actions such as booking and managing events.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: 1,
                        quotaType: QUOTA_TYPE_NUMBER_USERS
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: 2,
                        quotaType: QUOTA_TYPE_NUMBER_USERS
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: 3,
                        quotaType: QUOTA_TYPE_NUMBER_USERS
                    }
                ]
            },
            {
                title: 'Standard Users',
                description: 'Allow your employees and other staff members to login to the system to view information.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED_USERS
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED_USERS
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED_USERS
                    }
                ]
            },
            // {
            //     title: 'Feature Title',
            //     description: 'description',
            //     featureType: FEATURE_TYPE_FEATURE,
            //     plansAvailableFor: [
            //         LITE_PLAN,
            //         PLUS_PLAN,
            //         //PROFESSIONAL_PLAN
            //     ]
            // }
        ]
    },
    {
        title: 'General',
        description: null,
        image: {
            imageUrl: image1,
            imageAlt: ''
        },
        features: [
            {
                title: 'Event Management',
                description: 'Enter and manage your events using the DJ Manager calendar.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                icon: faCalendar,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    }
                ]
            },
            {
                title: 'Client Management',
                description: 'Add client records and associate with events to keep track of bookings and contact information.',
                icon: faUsers,
                featureType: FEATURE_TYPE_QUOTA_BASED,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    }
                ]
            },
            {
                title: 'Venue Management',
                description: 'Assign events to a venue and enter relevant venue information.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                icon: faBuilding,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    }
                ]
            },
            {
                title: 'Task Tracking',
                description: 'Use the inbuilt task tracker to track your relevant tasks, and link these to items such as events, clients and more.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                icon: faTasks,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: LITE_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_UNLIMITED
                    }
                ]
            },
        ]
    },
    {
        title: 'Employee Management',
        description: 'Use our employee tools to manage any employee/staff assignments against events.',
        features: [
            {
                title: 'Employee Storage',
                description: 'Store employees in the system to track wages and other information.',
                featureType: FEATURE_TYPE_QUOTA_BASED,
                icon: faBriefcase,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ],
                planQuotas: [
                    {
                        planKey: PLUS_PLAN,
                        quotaAmount: 3,
                        quotaType: QUOTA_TYPE_NUMBER_EMPLOYEES
                    },
                    {
                        planKey: PROFESSIONAL_PLAN,
                        quotaAmount: null,
                        quotaType: QUOTA_TYPE_NUMBER_EMPLOYEES
                    }
                ]
            },
            {
                title: 'Event Assignments',
                description: 'Select employees against your events and assign pay and role details as required.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Dashboard',
        description: 'The hub of the DJ Manager system is the Dashboard, showing all your key information in one simple place.',
        features: [
            {
                disabled: true,
                title: 'Dashboard Configuration',
                icon: faCog,
                description: 'Customise the default dashboard to your liking',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Calendar',
                description: 'A calendar shows all events, leads, enquiries and booked off dates with their statuses.',
                icon: faCalendarAlt,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Quick Stats',
                description: 'Automated quick stats use the reporting system to generate instant statistics directly on your dashboard.',
                icon: faTachometerAlt,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Client System',
        description: 'Provide your clients with 24/7 access to the client system, with the ability to manage event details, E-sign contracts and more.',
        features: [
            {
                title: 'Client Logins',
                description: 'Allow an unlimited number of clients to login via their own client portal.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Manage my Event',
                description: 'Clients can view and manage their upcoming and past events using an easy to use web interface.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'E-Sign Contract Signing',
                description: 'Send clients a link and get your contracts signed via the E-sign portal. Clients can review and electronically sign documents.',
                icon: faFileContract,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Online Payment Gateway',
                description: 'Securely Receive online payments from your clients and automatically assign received funds to events.',
                disabled: true,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    //LITE_PLAN,
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Enquiry Tools',
        description: 'Track enquiries and their current statuses efficiently using our tools, with funnel-based reports available to optimize your booking process.',
        features: [
            {
                title: 'Enquiry Tracking',
                description: 'Store an unlimited number of enquiries within the system.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'One-click converting to Leads',
                description: 'Convert prospective enquiries to leads with one simple click, bringing all the information across automatically.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Lead/Opportunities System',
        description: "Track potential sales opportunities using the inbuilt 'Lead' type, allowing quote generation and additional information to be collected.",
        features: [
            {
                title: 'Lead Tracking',
                description: 'Store an unlimited number of leads within the system.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'One-click converting to Events',
                description: 'Convert prospective enquiries to events with one simple click, also transferring any quotations and other information.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Financials System',
        description: 'Use our financial tools to effectively track income and expenses in an easy to use ledger.',
        features: [
            {
                title: 'Quotation System',
                description: 'Create and generate quotation documentation which you can quickly and easily send to clients. Convert these quotes to invoices as required.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Income and Expenses Tracking',
                description: 'Log transactons in the system and assign categories to your transactions.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Attach transactions to events',
                description: 'Link your transactions against specific events to automatically calculate outstanding totals for events.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Integrations',
        description: 'What is a modern day system without integrations? Connect your app to other solutions such as Google Calendar / Outlook.',
        features: [
            {
                title: 'Automated iCalendar Feeds',
                description: 'See all your calendar events in one place. Link your DJ Manager calendar stream with any iCalendar compatible software so you always have the full picture.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Configurable iCalendar Feeds',
                description: 'Configure multiple seperate iCalendar feeds as required based on item type, status, and assigned employees.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'External Email Accounts',
                description: 'Connect your external email accounts to have the ability to send emails directly from DJ Manager. Generate a contract and request E-Signature in one click.',
                featureStatus: FEATURE_STATUS_BETA,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Connect with Zapier',
                description: 'Connect with Zapier to allow communication between DJ Manager and over 2000 applications. Utilise triggers and actions within the Zapier platform to integrate.',
                featureStatus: FEATURE_STATUS_BETA,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Developer API',
                description: 'Use our new Developer API to write your own integrations directly with DJ Manager. Extract and insert information through the HTTP APIs.',
                featureStatus: FEATURE_STATUS_BETA,
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Web Tools',
        description: 'Embeddable tools to allow direct integration between your website and the DJ Manager system.',
        features: [
            {
                title: 'Enquiry Form',
                description: 'Embed an enquiry form allowing your prospective clients to submit enquiries directly into DJ Manager.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Live Requests System',
                description: 'Generate per-event QR codes to allow event guests to request songs in realtime.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PLUS_PLAN,
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
    {
        title: 'Supplier/Vendor Management',
        description: 'Enter and manage suppliers/vendors within the software. You can also assign them to relevant events.',
        features: [
            {
                title: 'Supplier/Vendor Storage',
                description: 'Setup an unlimited number of suppliers/vendors in the software.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PROFESSIONAL_PLAN
                ]
            },
            {
                title: 'Supplier Event Assignments',
                description: 'Assign suppliers/vendors against relevant events.',
                featureType: FEATURE_TYPE_FEATURE,
                plansAvailableFor: [
                    PROFESSIONAL_PLAN
                ]
            }
        ]
    },
];

export {
    featureGroups,
    FEATURE_TYPE_QUOTA_BASED,
    FEATURE_TYPE_FEATURE,
    QUOTA_TYPE_NUMBER_EMPLOYEES,
    QUOTA_TYPE_NUMBER_USERS,
    QUOTA_TYPE_UNLIMITED_USERS,
    QUOTA_TYPE_UNLIMITED,
    FEATURE_STATUS_STABLE,
    FEATURE_STATUS_BETA,
    FEATURE_STATUS_COMING_SOON,
};