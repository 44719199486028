
const LITE_PLAN = 'litePlan';
const PLUS_PLAN = 'plusPlan';
const PROFESSIONAL_PLAN = 'professionalPlan';

const plans = [
    {
        title: 'Lite Plan',
        planKey: LITE_PLAN
    },
    {
        title: 'Plus Plan',
        planKey: PLUS_PLAN
    },
    {
        title: 'Pro Plan',
        planKey: PROFESSIONAL_PLAN
    }
];

export {
    LITE_PLAN,
    PLUS_PLAN,
    PROFESSIONAL_PLAN,
    plans
};